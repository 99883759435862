import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Header, Label, Button, Form, Grid, Segment, CheckboxProps, Select, Dropdown, DropdownProps } from "semantic-ui-react";
import { observer, useForceUpdate } from "mobx-react-lite";
import { RouteComponentProps } from "react-router-dom";

//import { OnChange } from "react-final-form-listeners";
import { ErrorMessage, Formik, useField, validateYupSchema, yupToFormErrors, Field } from "formik"
import * as Yup from 'yup';

import TextInputFormik from "../../../app/common/form/TextInputFormik";
import SelectInputFormik from "../../../app/common/form/SelectInputFormik";
import CheckboxNew from "../../../app/common/form/CheckboxNew";

import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";

import RenderIf from "../../../app/common/form/RenderIf";
import { IInitiationFormValues } from "../../../app/models/initiation";
import { ISuffix } from "../../../app/models/suffix";
import { is } from "date-fns/locale";
import "../../../app/layout/styles.css"
import "../../../app/layout/formStyles.css"



interface DetailParams {
  id: string;
}

const InitiationForm: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { accountInfoUserName, accountInfoUserEmail } = rootStore.userStore;
  const {
    createInitiation,
    loadInitiation,
    //editConflict,
    submitting,
    loadNewInitiationInfo,
    admins,
    managers,
    iCompanies,
    municipalities,
    suffixes,
    municipalSuffixes,
    projectSuffixes,
    bProjectTemplates,
    bServerFolders,
    bClients,
    serverFolderClients
  } = rootStore.initiationStore;

  const [initiation, setInitiation] = useState(new IInitiationFormValues());
  
  const [loading, setLoading] = useState(false);

  

  //const [sfx, setSfx] = useState(suffixes);

  useEffect(() => {
    if (match.params.id) {
      setLoading(true);
      loadInitiation(parseInt(match.params.id))
        .then((initiation: IInitiationFormValues) => {
          let tempInitiation = new IInitiationFormValues(initiation);
          tempInitiation.projectManagerName = initiation.projectManagerName + "|" + initiation.projectManagerEmail;
          setInitiation(new IInitiationFormValues(tempInitiation));
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(true);

      //loadNewInitiationInfo().finally(() => setLoading(false));
      loadNewInitiationInfo()
      //.then(() => getAllSuffixes())
      .finally(() => setLoading(false));

    }
  }, [loadNewInitiationInfo, loadInitiation, match.params.id]);

  const isBergmannInitiation = (initiationCompany: string) => {

    //console.log("fire bergmann Initiation Company: " + initiationCompany);

    if (+initiationCompany === 2) {   // Bergmann
      return true;
    }

    return false;
  };

  const isCEDInitiation = (initiationCompany: string) => {

    //console.log("fire ced Initiation Company: " + initiationCompany);

    if (+initiationCompany === 1) {  // CED
      return true;
    }

    return false;
  };

  const isHWInitiation = (initiationCompany: string) => {
    if (+initiationCompany === 3) { //Hilgart Wilson
      return true;
    }
    return false;
  }

  const isProposal = (initiationSuffix: string) => {
    if(initiationSuffix === "P") {
      
      return true;
    }
    return false;
  }

  const isAddClient = (bergmannClient: string | undefined) => {
    
    if(bergmannClient == "0") { 
      return true;
    }
    else {
      return false;
    }
    
  }

  

  const validationSchema = Yup.object({
    initiationCompany: Yup.string().required('Company is required.'),
    name: Yup.string().required("Project name is required.").max(140, "Maximum character length exeeded."),
    projectManagerName: Yup.string().required("Project manager name is required."),
    suffix: Yup.string().required("Suffix is required."),



    prefix: Yup.string().when(['initiationCompany'], {
      is: (initiationCompany: string) => initiationCompany === '1' && initiation.isMunicipal,
      then: Yup.string().required("Municipality is required.") //Select Municipality
    }),

    
    bergmannServerFolder: Yup.string().when(['initiationCompany', 'suffix'], {
      is: (initiationCompany: string, suffix:string) => initiationCompany === '2' && suffix !== 'P',
      then: Yup.string().required('Server folder is required.').not(["14"],"Marketing folder can only be used for proposal."),
      otherwise: Yup.string().when(['initiationCompany', 'suffix'], {
        is: (initiationCompany: string, suffix: string) => initiationCompany === '2' && suffix === 'P',
        then: Yup.string().required('Server folder is required.').matches(/^14$/, "Marketing folder is required for proposal")
      })
    }),

    bergmannClient: Yup.string().when(['initiationCompany','suffix'], {
      is: (initiationCompany: string, suffix:string) => initiationCompany === '2' && suffix !== 'P',
      then: Yup.string().required('Client is required.')
    }),
    bergmannProjectTemplate: Yup.string().when(['initiationCompany','suffix'], {
      is: (initiationCompany: string, suffix:string) => initiationCompany === '2' && suffix !== 'P',
      then: Yup.string().required('Project template is required.')
    }),
    
   
    bergmannNewClientName: Yup.string().when(['initiationCompany','bergmannClient','suffix',], {
      is: (initiationCompany: string, bergmannClient: string, suffix: string) => initiationCompany === '2' && suffix !== 'P' && bergmannClient === '0',
      then: Yup.string().required('New client is required.').matches(/^[^\\/:*?"<>|]+$/, `The following characters cannot be used: \\ / : * ? " < > |`).max(140, "Maximum character length exeeded."),
    }),

  });


  const handleServerFolderChange = ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, values: IInitiationFormValues) => {

    const { ...initiation } = values;
   
    setInitiation({...initiation, bergmannServerFolder: data.value as string, bergmannClient: ''});
    //loadTasks(data.value as string);
    setLoading(true);

    serverFolderClients(data.value as number).then(() => { setLoading(false); });

  });



  const handleICompanyChange = ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, values: IInitiationFormValues) => {

    const { ...initiation } = values;
    //console.log('inix: ' + JSON.stringify(values));
    setInitiation({...initiation, initiationCompany: data.value as string, suffix: '', bergmannServerFolder: '', isMunicipal: false, prefix: ''});
    //loadTasks(data.value as string);
  })

  const handleClientChange = ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, values: IInitiationFormValues) => {
    const {...initiation } = values;
    //setInitiation({...initiation, bergmannClient: data.value as string});
   
    if(initiation.bergmannClient == "0") { //
      setInitiation({...initiation, bergmannClient: data.value as string, bergmannNewClientName: data.value as string});
    }
    else {
      setInitiation({...initiation, bergmannClient: data.value as string, bergmannNewClientName: ''});
      //initiation.bergmannNewClientName = '';
      
    }
   
  })

  const handlePrefixChange = ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, values: IInitiationFormValues) => {
    const {...initiation} = values;
    
    setInitiation({...initiation, prefix: data.value as string})
  })
  
  const handleProjectTemplateChange = ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, values: IInitiationFormValues) => {
    const {...initiation} = values;
    setInitiation({...initiation, bergmannProjectTemplate: data.value as string})
  })


  const setMarketing = (data: DropdownProps, values: IInitiationFormValues) => {
    const {...initiation} = values;
    setInitiation({...initiation, bergmannServerFolder: data.value as string, suffix: "P", bergmannClient: "", bergmannNewClientName: "", bergmannProjectTemplate: ""});
  }

  

  const handleSuffixChange = ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, values: IInitiationFormValues) => {
    
    const { ...initiation } = values;
    
    //console.log(values);
    //console.log(values.bergmannServerFolder);
    
    if(data.value === "P") {
      //If proposal was selected, clear server folder, client, new client, and proposal 
      setInitiation({...initiation, suffix: data.value as string, bergmannClient: '', bergmannServerFolder: '', bergmannProjectTemplate: '', bergmannNewClientName: ''});
      
      
      setLoading(true);
      //Set the bergmann project folder to the marketing folder (14) so it displays on the frontend.
      setMarketing({value: 14}, values);
      
      
    }
    else {
      setInitiation({...initiation, suffix: data.value as string, bergmannClient: '',bergmannServerFolder: '', bergmannProjectTemplate: '', bergmannNewClientName: ''});
    }
    
    //loadTasks(data.value as string);
  })


  const handleMunicipalCbxInputChange = (event: any, data: CheckboxProps, values: IInitiationFormValues) => {
    //const { ...initiation } = values;
    let tempInitiation = new IInitiationFormValues(values);
    if (!data.checked) {
      tempInitiation.prefix = '';
     
    }
    if (tempInitiation.suffix !== 'P') {
      tempInitiation.suffix = '';
    } 
    tempInitiation.isMunicipal = data.checked!;
    setInitiation(new IInitiationFormValues(tempInitiation));
  };

  const setManagerEmail = (event: React.SyntheticEvent, data: any) => {
    //console.log(data.value);
    let mngr = managers.filter((manager) => manager.managerName === data.value);
    //console.log(mngr[0].managerEmail);
    initiation.projectManagerEmail = mngr[0].managerEmail;
  };

  const handleFinalFormSubmit = (values: IInitiationFormValues) => {

    console.log("submitting initiation");
    //Strip trailing space for user-entered fields.
    if (values.bergmannNewClientName != undefined)
    {
      values.bergmannNewClientName.trim();
    }
    if (values.name != undefined)
    {
      values.name.trim();
    }

    const { ...initiation } = values;
    //console.log(JSON.stringify(initiation));

    if (initiation.suffix === '') {
      toast.error("Select Suffix");
      return;
    }

    if (+initiation.initiationCompany === 1) {

      if (initiation.isMunicipal && initiation.prefix === '') {
        toast.error("Select Municipality");
        return;
      }


      initiation.bergmannProjectTemplate = undefined;
      initiation.bergmannClient = undefined;
      initiation.bergmannServerFolder = undefined;
      
    }
    
    if (+initiation.initiationCompany === 2 && initiation.suffix !== "P") {

      if (initiation.bergmannProjectTemplate === '') {
      
        toast.error("Select Project Template");
        return;
      }

      if (initiation.bergmannClient === '') {
        toast.error("Select Client");
        return;
      }

      if (initiation.bergmannServerFolder === '') {
        toast.error("Select Server Folder");
        return;
      }
  }
  //Else, set bergmannProjectTemplate to 0, as it is needed on the backend.
  //and set the other two fields to undefined, as they are not required if Proposal suffix is selected.
  else {
      initiation.bergmannProjectTemplate = undefined;
      initiation.bergmannClient = undefined;
      initiation.bergmannNewClientName = undefined;
  }
  if (+initiation.initiationCompany === 3) {

    if (initiation.isMunicipal && initiation.prefix === '') {
      toast.error("Select Municipality");
      return;
    }


    initiation.bergmannProjectTemplate = undefined;
    initiation.bergmannClient = undefined;
    initiation.bergmannServerFolder = undefined;
    
  }

  


    // extract email from name
    const [managerName, managerEmail] = values.projectManagerName.split("|");
    initiation.projectManagerName = managerName;
    initiation.projectManagerEmail = managerEmail;

    // extract email from name
    const [adminName, adminEmail] = values.adminName.split("|");
    initiation.adminName = adminName;
    initiation.adminEmail = adminEmail;
   

    // create initiation or or edit exisitng
    if (!initiation.id) {
      initiation.createDate = new Date(Date.now());

      initiation.createdByName = accountInfoUserName
        ? accountInfoUserName
        : "error: no name loaded in client";

        initiation.createdByEmail = accountInfoUserEmail
        ? accountInfoUserEmail
        : "error: no email loaded in client";

      
  
      //console.log("sending: " + JSON.stringify(initiation));
      createInitiation(initiation);
      
    } 
    else {
      //console.log("editing initiation");
//      editConflict(conflict);
    }
};



  return (
    <>
      <PageTitle title="Project Initiation" />
      <Grid>
        <Grid.Column width={16}>
          <Segment clearing>

          <Formik
            validationSchema={validationSchema}
            enableReinitialize={true}
            initialValues={initiation}
            onSubmit={handleFinalFormSubmit}
          >
          {({submitForm, setFieldTouched, isValid, isSubmitting, dirty, values, errors, touched }) => (
            <Form className= 'ui form' >
              
              <Header as="h3">Request Project Number</Header>

              <Segment>
                <Form.Group widths="equal">
                <Form.Field className="fieldDiv" error={touched.initiationCompany && errors.initiationCompany}>
                <Select placeholder='Select Company' 
                clearable
                value={values.initiationCompany} 
                name="initiationCompany"
                className="formField" 
                options={iCompanies.map((ic) => ({
                            key: ic.id,
                            value: ic.id,
                            text: ic.name,
                          }))}
                          
                          onBlur={() => {setFieldTouched('initiationCompany')}}
                          onChange={(e, v) => {handleICompanyChange(e, v, values);}}          
                />
                <ErrorMessage className="errorLabel" name="initiationCompany">
                  {msg => <Label basic color="red" >{msg}</Label>}
                </ErrorMessage>
                
                
                </Form.Field> 
                
                <TextInputFormik name='name' placeholder='Project Name' />
                </Form.Group>
                <RenderIf condition={isCEDInitiation(values.initiationCompany) || isHWInitiation(values.initiationCompany)}>
                  <Form.Group widths="equal">
                    <Form.Checkbox className="checkboxPadding"
                          name="isMunicipal"
                          label="Is Municipal Project"
                          checked={values.isMunicipal}
                          width={6}
                          onChange={(e, v) => { handleMunicipalCbxInputChange(e, v, values);
                            //This triggers if the checkbox is checked and the user is unchecking it.
                            if (values.isMunicipal) {
                              //this fix is not great: all fields are set to not touched
                              //to not conflict with the submit button. This code is ran
                              //when unchecking the municipal project checkbox, and is to allow the
                              //submit button to stay active when all other fields are filled in.
                              //Otherwise, the submit button would be disabled until any other field is
                              //touched by the user.
                              //this causes a delay when clicked in succession
                              setTimeout(() => {
                                setFieldTouched('prefix',false);
                              }, 50)
                            }

                            
                           

                          
                        }}
                        
                    />
                    <Form.Field className="fieldDiv" error={touched.prefix && errors.prefix}>
                    <Select name="prefix"
                            search
                            className="formField"
                            placeholder="Select Municipality"
                            clearable
                            value={values.prefix}
                            onBlur={() => {setFieldTouched('prefix')}}
                            disabled={!values.isMunicipal}
                            options={municipalities.map((m) => ({
                              key: m.id,
                              value: m.code,
                              text: m.name,
                            }))}
                          onChange={(e,v) => {
                            handlePrefixChange(e,v,values);
                            setTimeout(() => {
                              setFieldTouched('prefix');
                              }, 50);
                          }} 
                      />
                    <ErrorMessage className="errorLabel" name="prefix">
                        {msg => <Label basic color="red" >{msg}</Label>}
                      </ErrorMessage>
                    </Form.Field>
                     
                    <RenderIf condition={values.isMunicipal}>
                      <Form.Field className="fieldDiv" error={touched.suffix && errors.suffix}>
                      <Select name="suffix" className="formField" placeholder="Select Suffix"
                      clearable 
                      value={values.suffix}
                      onBlur={() => {
                        setFieldTouched('suffix');
                      }
                    }
                      
                      options={municipalSuffixes.map((s) => ({
                        key: s.id,
                        value: s.code,
                        text: s.name,
                      }))}
                      onChange={
                        (e,v) => {handleSuffixChange(e,v,values);
                        setTimeout(() => {
                          setFieldTouched('suffix');
                          }, 50);
                        }
                      }
                      />
                    <ErrorMessage className="errorLabel" name="suffix">
                      {msg => <Label basic color="red">{msg}</Label>}
                    </ErrorMessage>
                    </Form.Field>

                    </RenderIf>
                    
                    <RenderIf condition={!values.isMunicipal}>
                      <Form.Field className="fieldDiv" error={touched.suffix && errors.suffix}>
                      <Select name="suffix" className="formField" placeholder="Select Suffix" 
                      clearable
                      value={values.suffix }
                        options={projectSuffixes.map((s) => ({
                          key: s.id,
                          value: s.code,
                          text: s.name,
                        }))} 
                      onBlur={() => {setFieldTouched('suffix')}}
                      onChange={(e, v) => {
                        handleSuffixChange(e, v, values);
                        setTimeout(() => {
                        setFieldTouched('suffix');
                        }, 50)
                        }
                      }
                      />
                      <ErrorMessage className="errorLabel" name="suffix">
                        {msg => <Label basic color="red">{msg}</Label>}
                      </ErrorMessage>
                      </Form.Field>
                    </RenderIf>
                  </Form.Group>
                  <Form.Group widths="equal">
                    <SelectInputFormik name="adminName" placeholder="Select Admin" 
                      options={admins.map((a) => ({
                        key: a.adminEmail,
                        value: a.adminName + "|" + a.adminEmail,
                        text: a.adminName,
                      }))} />
                    
                    <SelectInputFormik name="projectManagerName" placeholder="Project Manager Name"
                      options={managers.map((m) => ({
                        key: m.managerEmail,
                        value: m.managerName + "|" + m.managerEmail,
                        text: m.managerName,
                      }))}
                    />
                  </Form.Group>
                </RenderIf>
                <RenderIf condition={isBergmannInitiation(values.initiationCompany)}>
                  <Form.Group widths="equal">
                      <SelectInputFormik name="projectManagerName"
                          placeholder="Select Project Manager"
                          
                          options={managers.map((m) => ({
                            key: m.managerEmail,
                            value: m.managerName + "|" + m.managerEmail,
                            text: m.managerName,
                          }))}
                      />
                    <Form.Field className="fieldDiv" error={touched.suffix && errors.suffix} >
                      <Select
                        name="suffix"
                        clearable
                        className="formField"
                        placeholder="Select Suffix"
                        value={values.suffix}
                        options={projectSuffixes.map((s) => ({
                          key: s.id,
                          value: s.code,
                          text: s.name,
                        }))} 
                        onBlur={() => {setFieldTouched('suffix')}}
                        onChange={
                          (e,v) => {
                            handleSuffixChange(e, v, values);
                            setTimeout(() => {
                              setFieldTouched('suffix');
                            });
                          }
                        }
                      />
                      <ErrorMessage className="errorLabel" name="suffix">
                        {msg => <Label basic color="red">{msg}</Label>}
                      </ErrorMessage>
                      </Form.Field>
                  </Form.Group>
                        <Form.Group widths="equal">
                          <Form.Field className="fieldDiv" error={touched.bergmannServerFolder && errors.bergmannServerFolder}>
                          <Select
                            disabled={isProposal(values.suffix)}
                            className='formField'
                            options={bServerFolders.map((sf) => ({
                              key: sf.id,
                              value: sf.id,
                              text: sf.name,
                            }))}
                            
                            value={values.bergmannServerFolder}
                            placeholder="Select Server Folder"
                            onBlur={() => {setFieldTouched('bergmannServerFolder')}}
                            onChange={(e, v) => {handleServerFolderChange(e, v, values)}}
                          />
                        <ErrorMessage className="errorLabel" name="bergmannServerFolder">
                          {msg=> <Label basic color="red">{msg}</Label>}
                        </ErrorMessage>
                        </Form.Field>
                        <Form.Field className="fieldDiv" error={touched.bergmannClient && errors.bergmannClient}>
                         <Select
                         disabled={isProposal(initiation.suffix)}
                         className="formField" 
                         name='bergmannClient' 
                         placeholder='Select Client'
                         search
                         clearable
                         value={values.bergmannClient} 
                         options={bClients.map((c) => ({
                            key: c.id,
                            value: c.id,
                            text: c.clientName,
                          }))} 
                          onBlur={() => {setFieldTouched('bergmannClient')}}
                          onChange={
                            (e, v) => {
                              handleClientChange(e, v, values);
                              setTimeout(() => {
                                setFieldTouched('bergmannClient');
                                });
                              }
                            }
                          
                          />
                          <ErrorMessage className="errorLabel" name="bergmannClient">
                            {msg => <Label basic color="red">{msg}</Label>}
                          </ErrorMessage>
                          </Form.Field>
                          {/* <Form.Field className="fieldDiv" error={touched.bergmannProjectTemplate && errors.bergmannProjectTemplate}>
                          <Select
                          disabled={isProposal(values.suffix)}
                          className='formField' 
                          name='bergmannProjectTemplate' 
                          placeholder='Select Project Template' 
                          
                          value={values.bergmannProjectTemplate} 
                          options={bProjectTemplates.map((pt) => ({
                              key: pt.id,
                              value: pt.id,
                              text: pt.name,
                            }))}
                          onBlur={() => {setFieldTouched('bergmannProjectTemplate')}} 
                          onChange={(e,v) => handleProjectTemplateChange(e,v,values )}
                          />
                          <ErrorMessage className="errorLabel" name="bergmannProjectTemplate">
                            {msg => <Label basic color="red">{msg}</Label>}
                          </ErrorMessage>
                        </Form.Field> */}
                        <SelectInputFormik name='bergmannProjectTemplate' placeholder="Select Project Template"
                        disabled={isProposal(values.suffix)}
                        options={bProjectTemplates.map((pt) => ({
                          key: pt.id,
                          value: pt.id,
                          text: pt.name,
                        }))}
                        />
                        </Form.Group>
                        <RenderIf condition={isAddClient(values.bergmannClient) && !isProposal(values.suffix)}>
                        <div className="fieldContainer">
                          <div className="formField">
                            <TextInputFormik name="bergmannNewClientName" placeholder='Enter Client Name' />
                          </div>
                        </div>
                        </RenderIf>  
                </RenderIf>
                
               
              </Segment>
              <Button
                  loading={submitting}
                  floated="right"
                  positive                                              
                  disabled={(isSubmitting || !isValid || (Object.keys(touched).length === 0 && touched.constructor === Object))}
                  type="submit"
                  content="Submit"
                  onClick={submitForm} //This onClick is needed, or else clicking the button wont do anything
              />
            </Form>
          )}
          </Formik>

          </Segment>
        </Grid.Column>
      </Grid>
    </>
  );
};

export default observer(InitiationForm);
