export interface IInitiation {

    id: number;

    prefix: string;
    year: string;
    number: string;
    suffix: string;
    name: string;

    folderPath: string;

    isMunicipal: boolean;

    adminName: string;
    adminEmail: string;
    
    projectManagerName: string;
    projectManagerEmail: string;

    createdByName: string;
    createdByEmail: string;
    createDate: Date;

    projectCreated: boolean;

    initiationCompany: string;              // number ?
    bergmannServerFolder?: string;
    //bergmannClientName?: string;
    bergmannClient?: string;
    bergmannProjectTemplate?: string;       // number ?
    bergmannNewClientName?: string;
    clientContract: number | null;
    contract: number | null;
    existingNumber: string;
}

export class IInitiationFormValues implements IInitiation {

    id: number = 0;

    prefix: string = '';
    year: string = '';
    number: string = '';
    suffix: string = '';
    name: string = '';

    folderPath: string = '';

    isMunicipal: boolean = false;

    adminName: string = '';
    adminEmail: string = '';

    projectManagerName: string = '';
    projectManagerEmail: string = '';

    createdByName: string = '';
    createdByEmail: string = '';
    createDate: Date = new Date(Date.now());

    projectCreated: boolean = false;

    clientContract: number | null = null;
    contract: number | null = null;

    initiationCompany: string = '';              // number = 0 ?
    bergmannServerFolder?: string = '';
    bergmannClient?: string = '';
    bergmannProjectTemplate?: string = '';       // number ?
    bergmannNewClientName?: string = ''; //If "Add Client" is selected for bergmannClient, this field is where the new client will be entered.

    existingNumber: string = '';

    constructor(init?: IInitiationFormValues) {
        Object.assign(this, init);
    }
}