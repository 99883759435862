import React, { useContext, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Header, Button, Form, Grid, Segment, Label, CheckboxProps, Select, Dropdown, DropdownOnSearchChangeData, DropdownProps } from "semantic-ui-react";
import { observer } from "mobx-react-lite";
import { Form as FinalForm, Field } from "react-final-form";
import { combineValidators, composeValidators, isAlphabetic, isRequired, isRequiredIf } from "revalidate";
import { RouteComponentProps } from "react-router-dom";
import arrayMutators from "final-form-arrays";


import { ErrorMessage, Formik, useField, validateYupSchema, yupToFormErrors } from "formik";
import * as Yup from 'yup';

import { RootStoreContext } from "../../../app/stores/rootStore";
import PageTitle from "../../../app/common/form/PageTitle";
import CheckboxInput from "../../../app/common/form/CheckboxInput";
import TextInput from "../../../app/common/form/TextInput";
import SelectInput from "../../../app/common/form/SelectInput";
import RenderIf from "../../../app/common/form/RenderIf";
import { IInitiationFormValues } from "../../../app/models/initiation";
import { ISuffix } from "../../../app/models/suffix";
import TextInputFormik from "../../../app/common/form/TextInputFormik";
import SelectInputFormik from "../../../app/common/form/SelectInputFormik";
import "../../../app/layout/styles.css";
import "../../../app/layout/formStyles.css";



const isAlpha = (str: string) => /^[a-zA-Z]*$/.test(str);

const validationSchema = Yup.object({
  initiationCompany: Yup.string().required('Company is required.'),
  name: Yup.string().required("Project name is required.").max(140, "Maximum character length exeeded."),
  existingNumber: Yup.string().required("Existing Proposal/Project Number is required."),


  suffix: Yup.string().when('existingNumber', {
    is: (existingNumber: string) => existingNumber !== undefined,
    then: Yup.string().when('existingNumber',{
      is: (existingNumber: string) => isAlpha(existingNumber.substring(0,3)),
      then: Yup.string(),
      otherwise: Yup.string().when('existingNumber',{
        is: (existingNumber: string) => isAlpha(existingNumber.substring(0,4)),
        then: Yup.string(),
        otherwise: Yup.string().required("Suffix is required")
      }),
      
    })
  }),


  projectManagerName: Yup.string().required("Project manager name is required."),
  
  
  //CED Specific Validations

  
  
  //Bergmann specific validations
  bergmannServerFolder: Yup.string().when(['initiationCompany', 'suffix'], {
    is: (initiationCompany: string, suffix:string) => initiationCompany === '2' && suffix !== 'P',
    then: Yup.string().required('Server folder is required.').not(["14"],"Marketing folder can only be used for proposal."),
    otherwise: Yup.string().when(['initiationCompany', 'suffix'], {
      is: (initiationCompany: string, suffix: string) => initiationCompany === '2' && suffix === 'P',
      then: Yup.string().required('Server folder is required.').matches(/^14$/, "Marketing folder is required for proposal")
    })
  }),

  bergmannClient: Yup.string().when(['initiationCompany','suffix'], {
    is: (initiationCompany: string, suffix:string) => initiationCompany === '2' && suffix !== 'P',
    then: Yup.string().required('Client is required.')
  }),
  bergmannProjectTemplate: Yup.string().when(['initiationCompany','suffix'], {
    is: (initiationCompany: string, suffix:string) => initiationCompany === '2' && suffix !== 'P',
    then: Yup.string().required('Project template is required.')
  }),
  bergmannNewClientName: Yup.string().when(['initiationCompany','bergmannClient','suffix',], {
    is: (initiationCompany: string, bergmannClient: string, suffix: string) => initiationCompany === '2' && suffix !== 'P' && bergmannClient === '0',
    then: Yup.string().required('New client is required.').matches(/^[^\\/:*?"<>|]+$/, `The following characters cannot be used: \\ / : * ? " < > |`).max(140, "Maximum character length exeeded."),
  }),

      //clientContract is either 1 (client), 2 (contract), or null (not in use)
      clientContract: Yup.number().nullable().when('initiationCompany', {
        is: (initiationCompany: string) => initiationCompany === '3',
        then: Yup.number()
        .typeError('Client or Contract must be a number.') // Ensure it's a number
        .required('Client or Contract is required.') // Cannot be null
        .nullable(false), 
        otherwise: Yup.number().nullable(true), // Allow null for all other cases
      })

});



interface DetailParams {
  id: string;
}

const ExistingInitiationForm: React.FC<RouteComponentProps<DetailParams>> = ({
  match,
}) => {
  const rootStore = useContext(RootStoreContext);
  const { accountInfoUserName, accountInfoUserEmail } = rootStore.userStore;
  const {
    createInitiation,
    createExistingInitiation,
    loadInitiation,
    submitting,
    loadNewInitiationInfo,
    admins,
    managers,
    iCompanies,
    removeMarketing,
    municipalities,
    //municipalSuffixes,
    //projectSuffixes,
    existingProjectSuffixes,
    bProjectTemplates,
    bServerFolders,
    bClients,
    projects,
    findProject,
    serverFolderClients
  } = rootStore.initiationStore;

  const [initiation, setInitiation] = useState(new IInitiationFormValues());
  const [loading, setLoading] = useState(false);
  removeMarketing(); //Remove the marketing folder.

  
  useEffect(() => {
    if (match.params.id) {
      setLoading(true);
      loadInitiation(parseInt(match.params.id))
        .then((initiation: IInitiationFormValues) => {
          
          let tempInitiation = new IInitiationFormValues(initiation);
          tempInitiation.projectManagerName = initiation.projectManagerName + "|" + initiation.projectManagerEmail;
          setInitiation(new IInitiationFormValues(tempInitiation));

        })
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      loadNewInitiationInfo().finally(() => setLoading(false));
    }
  }, []);

  const clientContractOptions = [
    { key: 'Client', text: 'Client', value: 1 },
    { key: 'Contract', text: 'Contract', value: 2 },
  ];

  
  const isExistingMunicipalProject = (existingProject: string) => {
    if (existingProject !== undefined) {
    
      if (isAlpha(existingProject.substring(0,3))) {
        return true;
      }
      if (isAlpha(existingProject.substring(0,4))) {
        return true;
      }
      

      
      
    }  
      
    return false;
  }

    //to clear project suffix if the project number becomes a municipal project
    const clearProjectSuffix = (existingNumber: string, values: IInitiationFormValues) => {
      
      if (values.suffix !== undefined) {
        if(isExistingMunicipalProject(values.existingNumber)) {
          
          const { ...initiation } = values;
          setInitiation({...initiation, suffix: ''});
        } 
        
      }
      
    }

  const isBergmannInitiation = (initiationCompany: string) => {

    //console.log("fire bergmann Initiation Company: " + initiationCompany);

    if (+initiationCompany === 2) {   // Bergmann
      return true;
    }

    return false;
  };

  const isCEDInitiation = (initiationCompany: string) => {

    //console.log("fire ced Initiation Company: " + initiationCompany);

    if (+initiationCompany === 1) {  // CED
      return true;
    }

    return false;
  };

  const isHWInitiation = (initiationCompany: string) => {
    if (+initiationCompany === 3) { //Hilgart Wilson
      return true;
    }
    return false;
  }

  const isAddClient = (bergmannClient: string | undefined) => {
    
    if(bergmannClient == "0") { 
      return true;
    }
    else {
      return false;
    }
    
  }

  const handleServerFolderChange = ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, values: IInitiationFormValues) => {

    const { ...initiation } = values;
    //console.log('inixx: ' + JSON.stringify(values));
    //console.log('dataxxx: ' + JSON.stringify(data));
    setInitiation({...initiation, bergmannServerFolder: data.value as string, bergmannClient: ''});
    //loadTasks(data.value as string);
    setLoading(true);
    serverFolderClients(data.value as number).then(() => { setLoading(false); });
  })

  const handleICompanyChange = ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, values: IInitiationFormValues) => {

    const { ...initiation } = values;
    //console.log('inix: ' + JSON.stringify(values));
    setInitiation({...initiation, initiationCompany: data.value as string, bergmannServerFolder: '', bergmannClient: '', clientContract: null});
    //loadTasks(data.value as string);
  })

  const handleProjectSearchChange = (event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownOnSearchChangeData, values: IInitiationFormValues) => {

    //console.log('DropdownOnSearchChangeData: ' + JSON.stringify(data));
    //console.log('DropdownOnSearchChangeData inix: ' + JSON.stringify(values));

    //const { ...initiation } = values;

    //setInitiation({...initiation, existingNumber: (data.searchQuery as string).toUpperCase()});

    if (data.searchQuery.length >= 4) {
        setLoading(true);
        findProject(data.searchQuery).then(() => { setLoading(false); });
    };

};

//Execute this on project number onBlur? or in handleProjectSearchChange? 
const findProjectManager = (event: React.SyntheticEvent<HTMLElement, Event>, value: number) => {
  //Use value to find the project, get project info, and then return the project manager name and admin.
  
} 


  const handleProjectChange = ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, values: IInitiationFormValues) => {

    const { ...initiation } = values;
    //console.log('DropdownProps: ' + JSON.stringify(values));
    
    //loadTasks(data.value as string);

      if (isExistingMunicipalProject(data.value as string)) {
        
        setInitiation({...initiation, existingNumber: data.value as string, suffix: ''});
      }
      else {
        
        setInitiation({...initiation, existingNumber: data.value as string});
      }

  })


  const handleSuffixChange = ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, values: IInitiationFormValues) => {

    const { ...initiation } = values;
    //console.log('inix: ' + JSON.stringify(values));
    setInitiation({...initiation, suffix: data.value as string});
    //loadTasks(data.value as string);
  })

  const handleClientChange = ((event: React.SyntheticEvent<HTMLElement, Event>, data: DropdownProps, values: IInitiationFormValues) => {
    const {...initiation } = values;
    setInitiation({...initiation, bergmannClient: data.value as string});
   
    if(initiation.bergmannClient == "0") { //
      setInitiation({...initiation, bergmannClient: data.value as string, bergmannNewClientName: data.value as string});
    }
    else {
      setInitiation({...initiation, bergmannClient: data.value as string, bergmannNewClientName: undefined});
      //initiation.bergmannNewClientName = '';
      
    }
  })



  const handleMunicipalCbxInputChange = (event: any, data: CheckboxProps, values: IInitiationFormValues) => {
    //const { ...initiation } = values;

    let tempInitiation = new IInitiationFormValues(values);

    if (!data.checked) {
      tempInitiation.prefix = '';
      //getProjectSuffixes();
    } else {
      //getMunicipalProjectSuffixes();
    }

    tempInitiation.isMunicipal = data.checked!;
      
    setInitiation(new IInitiationFormValues(tempInitiation));

  };

  const setManagerEmail = (event: React.SyntheticEvent, data: any) => {
    //console.log(data.value);
    let mngr = managers.filter((manager) => manager.managerName === data.value);
    console.log(mngr[0].managerEmail);
    initiation.projectManagerEmail = mngr[0].managerEmail;
  };


  
  const handleFinalFormSubmit = (values: IInitiationFormValues) => {

    console.log("submitting initiation");
    //Strip trailing whitespace for user-entered fields. 
    if (values.bergmannNewClientName != undefined)
    {
      values.bergmannNewClientName.trim();
    }
    if (values.name != undefined)
    {
      values.name.trim();
    }

    const { ...initiation } = values;

    if (!isExistingMunicipalProject(values.existingNumber))
    {
      if (initiation.suffix === '') {
        toast.error("Select Suffix");
        return;
      }
    }

    if (+initiation.initiationCompany === 1) {
      initiation.bergmannProjectTemplate = undefined;
      initiation.bergmannClient = undefined;
      initiation.bergmannServerFolder = undefined;
      initiation.bergmannNewClientName = undefined;
      initiation.clientContract = null;
    }

    if (+initiation.initiationCompany === 2) {

      if (initiation.bergmannProjectTemplate === '') {
        toast.error("Select Project Template");
        return;
      }

      if (initiation.bergmannClient === '') {
        toast.error("Select Client");
        return;
      }

      if (initiation.bergmannServerFolder === '') {
        toast.error("Select Server Folder");
        return;
      }
    }
    if (+initiation.initiationCompany === 3) {
      initiation.bergmannProjectTemplate = undefined;
      initiation.bergmannClient = undefined;
      initiation.bergmannServerFolder = undefined;
      initiation.bergmannNewClientName = undefined;
    }


    // extract email from name
    const [managerName, managerEmail] = values.projectManagerName.split("|");
    initiation.projectManagerName = managerName;
    initiation.projectManagerEmail = managerEmail;

    // extract email from name
    const [adminName, adminEmail] = values.adminName.split("|");
    initiation.adminName = adminName;
    initiation.adminEmail = adminEmail;

    // create initiation or or edit exisitng
    if (!initiation.id) {
      initiation.createDate = new Date(Date.now());

      initiation.createdByName = accountInfoUserName
        ? accountInfoUserName
        : "error: no name loaded in client";

        initiation.createdByEmail = accountInfoUserEmail
        ? accountInfoUserEmail
        : "error: no email loaded in client";

      console.log("sending: " + JSON.stringify(initiation));
      //console.log("creating initiation");
      createExistingInitiation(initiation);
    } else {
      console.log("editing initiation");
//      editConflict(conflict);
    }




  };

  //console.log(municipalities);

  

  return (
    <>
      <PageTitle title="Project Initiation" />
      <Grid>
        <Grid.Column width={16}>
          <Segment clearing>
            <Formik
              validationSchema={validationSchema}
              enableReinitialize={true}
              initialValues={initiation}
              onSubmit={handleFinalFormSubmit}
            >
              {({submitForm, setFieldTouched, isValid, isSubmitting, dirty, values, errors, touched}) => (
                <Form className= 'ui form'>
                  <Header as="h3">Request Project Number From Existing Proposal/Project</Header>
                  <Segment>
                    <Form.Group widths="equal">
                    <Form.Field className="fieldDiv" error={touched.initiationCompany && errors.initiationCompany}>
                    <Select placeholder='Select Company' 
                      clearable
                      value={values.initiationCompany} 
                      name="initiationCompany"
                      className="formField" 
                      options={iCompanies.map((ic) => ({
                              key: ic.id,
                              value: ic.id,
                              text: ic.name,
                          }))}
                          
                          onBlur={() => {setFieldTouched('initiationCompany')}}
                          onChange={(e, v) => {handleICompanyChange(e, v, values);}}          
                />
                <ErrorMessage className="errorLabel" name="initiationCompany">
                  {msg => <Label basic color="red" >{msg}</Label>}
                </ErrorMessage>
                    </Form.Field>
                    <TextInputFormik name='name' placeholder='New Project Name'/>
                    </Form.Group>
                    
                    <RenderIf condition={isCEDInitiation(values.initiationCompany) || isHWInitiation(values.initiationCompany)}>
                    <Form.Group widths="equal">
                      <Form.Field className="fieldDiv" error={touched.existingNumber && errors.existingNumber}>
                          <Select
                            name='existingNumber'
                            selection
                            search
                            //width={8}
                            fluid
                            className="formField"
                            placeholder='Enter Existing Proposal/Project Number'
                            onChange={(e, v) => {
                              handleProjectChange(e, v, values);
                              setTimeout(() => {
                                setFieldTouched('existingNumber');
                              }) 
                            }
                            
                            }
                            onBlur={() => {setFieldTouched('existingNumber');
                                           
                                          }
                                  }
                            onSearchChange={(e, v) => { handleProjectSearchChange(e, v, values) }}
                            value={values.existingNumber}
                            options={projects.map((p) => ({
                              key: p.title,
                              value: p.title,
                              text: p.title,
                            }))}

                          />
                        <ErrorMessage className="errorLabel" name="existingNumber">
                          {msg => <Label basic color="red" >{msg}</Label>}
                        </ErrorMessage>
                      </Form.Field>
                      <Form.Field className="fieldDiv" error={touched.suffix && errors.suffix}>
                        <Select
                            name="suffix"
                            placeholder="Select Suffix"
                            value={values.suffix}
                            search
                            disabled={isExistingMunicipalProject(values.existingNumber)}
                            className="formField"
                            onBlur={() => {setFieldTouched('suffix');
                                            
                              }
                            }
                            onChange={
                              (e,v) => {
                                handleSuffixChange(e,v,values);
                                setTimeout(() => {
                                  setFieldTouched('suffix');
                                })
                              }
                            
                            }
                            options={existingProjectSuffixes.map((s) => ({
                              key: s.id,
                              value: s.code,
                              text: s.name,
                            }))}
                            
                        />
                        <ErrorMessage className="errorLabel" name="suffix">
                          {msg => <Label basic color="red" >{msg}</Label>}
                        </ErrorMessage>
                        </Form.Field>
                    </Form.Group>
                    <Form.Group widths="equal">
                          <SelectInputFormik name="adminName" placeholder="Select Admin"
                            options={admins.map((a) => ({
                            key: a.adminEmail,
                            value: a.adminName + "|" + a.adminEmail,
                            text: a.adminName,
                          }))} 
                          />
                          <SelectInputFormik name="projectManagerName" placeholder="Project Manager Name"
                            options={managers.map((m) => ({
                            key: m.managerEmail,
                            value: m.managerName + "|" + m.managerEmail,
                            text: m.managerName,
                          }))}
                          />
                      </Form.Group>
                    </RenderIf> {/* End of CED Initiation specific fields*/}
                  <RenderIf condition={isHWInitiation(values.initiationCompany)}>
                    <Form.Group widths="equal">
                        <SelectInputFormik name="clientContract" placeholder="New Client or Contract"
                        options={clientContractOptions}
                        />
                    </Form.Group>
                  </RenderIf>
                  <RenderIf condition={isBergmannInitiation(values.initiationCompany)}>
                    <Form.Group widths="equal">
                      <Form.Field className="fieldDiv" error={touched.existingNumber && errors.existingNumber}>
                        <Select
                            name='existingNumber'
                            
                            selection
                            search
                            fluid
                            placeholder='Enter Existing Proposal/Project Number'
                            className="formField"
                            onChange={
                              (e, v) => { 
                                handleProjectChange(e, v, values);
                                setTimeout(() => {
                                  setFieldTouched('existingNumber');
                                })
                              }
                            }
                            onSearchChange={(e, v) => { handleProjectSearchChange(e, v, values) }}
                            onBlur={() => setFieldTouched('existingNumber')}
                            value={values.existingNumber}
                            options={projects.map((p) => ({
                              key: p.title,
                              value: p.title,
                              text: p.title,
                            }))}
                        />
                        <ErrorMessage className="errorLabel" name="existingNumber">
                          {msg => <Label basic color="red" >{msg}</Label>}
                        </ErrorMessage>
                      </Form.Field>
                      <SelectInputFormik name="projectManagerName" placeholder="Project Manager Name"
                      options={managers.map((m) => ({
                        key: m.managerEmail,
                        value: m.managerName + "|" + m.managerEmail,
                        text: m.managerName,
                      }))}
                    />
                      <Form.Field className="fieldDiv" error={touched.suffix && errors.suffix}>
                      <Select
                          name="suffix"
                          className="formField"
                          placeholder="Select Suffix"
                          value={values.suffix}
                          //search
                          disabled={isExistingMunicipalProject(values.existingNumber)}
                          onBlur={() => setFieldTouched("suffix")}
                          options={existingProjectSuffixes.map((s) => ({
                            key: s.id,
                            value: s.code,
                            text: s.name,
                          }))}
                          onChange={
                            (e,v) => {
                              handleSuffixChange(e,v,values);
                              setTimeout(() => {
                                setFieldTouched('suffix');
                              })
                            }
                          }
                          
                        />

                      <ErrorMessage className="errorLabel" name="suffix">
                        {msg => <Label basic color="red" >{msg}</Label>}
                      </ErrorMessage>
                      </Form.Field>
                      
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Form.Field className="fieldDiv" error={touched.bergmannServerFolder && errors.bergmannServerFolder}>
                        <Select
                            name='bergmannServerFolder'
                            className="formField"
                            selection
                            //search
                            //width={6}
                            fluid
                            placeholder='Select Folder Path'
                            onChange={
                              (e, v) => { 
                                handleServerFolderChange(e, v, values);
                                setTimeout(() => {
                                  setFieldTouched('bergmannServerFolder');
                                })
                              }
                            }
                            onBlur={() => setFieldTouched('bergmannServerFolder')}
                            value={values.bergmannServerFolder}
                            options={bServerFolders.map((sf) => ({
                              key: sf.id,
                              value: sf.id,
                              text: sf.name,
                            }))}
                          />
                          <ErrorMessage className="errorLabel" name="bergmannServerFolder">
                            {msg => <Label basic color="red" >{msg}</Label>}
                          </ErrorMessage>
                      </Form.Field>
                      <Form.Field className='fieldDiv' error={errors.bergmannClient && touched.bergmannClient}>
                      <Select
                          name="bergmannClient"
                          placeholder="Select Client"
                          className="formField"
                          search
                          width={5}
                          onChange={(e, v) => {
                            handleClientChange(e, v, values);
                            setTimeout(() => {
                              setFieldTouched('bergmannClient');
                            });
                            }
                          }
                          onBlur={() => setFieldTouched("bergmannClient")}
                          value={values.bergmannClient}
                          options={bClients.map((c) => ({
                            key: c.id,
                            value: c.id,
                            text: c.clientName,
                          }))}
                          //component={SelectInput}
                        />
                        <ErrorMessage className="errorLabel" name="bergmannClient">
                            {msg => <Label basic color="red" >{msg}</Label>}
                        </ErrorMessage>
                      </Form.Field>
                      <Form.Field className="fieldDiv" error={errors.bergmannProjectTemplate && touched.bergmannProjectTemplate}>
                        <SelectInputFormik
                        name="bergmannProjectTemplate"
                        
                        placeholder="Select Project Template"
                        options={bProjectTemplates.map((pt) => ({
                          key: pt.id,
                          value: pt.id,
                          text: pt.name,
                        }))}/>
                      </Form.Field>

                    </Form.Group>
                    <RenderIf condition={isAddClient(values.bergmannClient) && isBergmannInitiation(values.initiationCompany)}>
                    <div className="fieldContainer">
                      <div className="formField">
                        <TextInputFormik name="bergmannNewClientName" placeholder="Add New Client"/>
                      </div>
                    </div>
                    </RenderIf>

                  </RenderIf>

                  </Segment> {/*end of form fields */}
                  <Button 
                    loading={submitting}
                    floated="right"
                    positive                     
                    disabled={isSubmitting || !isValid || (Object.keys(touched).length === 0 && touched.constructor === Object)}
                    type="submit"
                    content="Submit"
                    onClick={submitForm}
                  />
                </Form>
              )}
              </Formik>
          </Segment>
        </Grid.Column>
      </Grid>

    </>
  
  );
};

export default observer(ExistingInitiationForm);
